import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/Playroom/';

class AdminPlayroomService {
  resetLastPriceChange(playroomId: string) {
    return ApiService.post(`${API_URL}ResetsLastPriceChangeDate`, { playroomId });
  }

  getForEdit(playroomId: string) {
    return ApiService.get(`${API_URL}Edit?playroomId=${playroomId}`);
  }

  save(playroom: any, playroomId: string) {
    playroom.provisionPercentage = playroom.provisionPercentage.replace(',', '.');
    playroom.regularTaxRate = playroom.regularTaxRate.replace(',', '.');
    playroom.reducedTaxRate = playroom.reducedTaxRate.replace(',', '.');
    playroom.forceShowWebsiteLink = playroom.forceShowWebsiteLink.toString();
    return ApiService.post(`${API_URL}Edit?playroomId=${playroomId}`, playroom);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new AdminPlayroomService();
