var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"items":_vm.playrooms,"fields":[
      'name',
      'owner',
      {
        key: 'activeByAdmin',
        label: 'Active by Admin',
        sorter: true,
        filter: false,
        _style: 'width:10%',
      },
      {
        key: 'activeByOwner',
        label: 'Active by Host',
        sorter: true,
        filter: false,
        _style: 'width:10%',
      },
      {
        key: 'hasCalenderImport',
        label: 'Calender Import',
        sorter: true,
        filter: false,
        _style: 'width:10%',
      },
      'productVariant',
      {
        key: 'actions',
        label: 'Aktionen',
        sorter: false,
        filter: false,
      } ],"table-filter-value":_vm.tableFilterValue,"sorter":{ external: true, resetable: true },"sorter-value":_vm.sorterValue,"hover":"","loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"activeByAdmin",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('span',{class:item.activeByAdmin ? 'text-success' : 'text-warning'},[_vm._v(" "+_vm._s(item.activeByAdmin ? 'Ja' : 'Nein')+" ")])])]}},{key:"activeByOwner",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('span',{class:item.activeByOwner ? 'text-success' : 'text-warning'},[_vm._v(" "+_vm._s(item.activeByOwner ? 'Ja' : 'Nein')+" ")])])]}},{key:"hasCalenderImport",fn:function(ref){
    var item = ref.item;
return [_c('td',{class:item.hasCalenderImport ? 'text-success' : 'text-warning'},[_vm._v(" "+_vm._s(item.hasCalenderImport ? 'Ja' : 'Nein')+" ")])]}},{key:"actions",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('CDropdown',{staticClass:"m-2",attrs:{"color":"primary","toggler-text":""}},[_c('CDropdownItem',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.playrooms.OwnerPlayroomIndex.show'),expression:"'pages.owner.playrooms.OwnerPlayroomIndex.show'"}],attrs:{"to":{
              name: 'PublicPlayroomDetail',
              params: { playroomId: item.playroomId },
            }}}),_c('CDropdownItem',{attrs:{"to":{
              name: 'AdminPlayroomEdit',
              params: { playroomId: item.playroomId },
            }}},[_vm._v(" Ändern ")])],1)],1)]}}])}),(_vm.pages > 1)?_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }